<template>
  <div class="ec-container">
    <div :ref="id" :style="{ width: width, height: height }"></div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'a'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    Ldata: {
      type: Array,
      default() {
        return []
      }
    },
    split: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      timer: null
    }
  },
  watch: {
    Ldata() {
      this.drawLine()
    }
  },

  methods: {
    drawLine() {
      this.myChart.setOption(this.setLOption())
    },

    setLOption() {
      return {
        animation: false,
        backgroundColor: '#131f30',
        grid: {
          show: false,
          left: '8%',
          right: '14%',
          bottom: '10%',
          top: '5%'
        },
        xAxis: {
          type: 'category',
          data: this.split,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#626263'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#626263'],
              width: 1,
              type: 'dashed'
            }
          }
        },
        yAxis: {
          type: 'value',
          scale: true,
          position: 'right',
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#626263'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#626263'],
              width: 1,
              type: 'dashed'
            }
          }
        },
        series: [
          {
            data: this.Ldata,
            type: 'line',

            labelLine: {
              showAbove: true
            },
            lineStyle: {
              color: '#f3ad00'
            },
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#493915' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#17181a' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              },
              origin: 'start',
              opcity: 0.7
            },
            endLabel: {
              show: true,
              formatter: function(value) {
                return value.value
              }
            }
          }
        ]
      }
    }
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    this.$nextTick(() => {
      this.myChart = this.$echarts.init(this.$refs[this.id])
      this.drawLine()
    })
  }
}
</script>

<style>
.ec-container {
  padding: 0 2px;
}
</style>
