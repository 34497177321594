<template>
  <div class="ec-container">
    <div :ref="id" :style="{ width: width, height: height }"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLocalTime } from '@/utils/validate'
export default {
  props: {
    id: {
      type: String,
      default: 'a'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    dayMode: {
      type: Boolean,
      default: false
    },
    Kdata: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  watch: {
    Kdata(newValue) {
      if (newValue.length) {
        this.drawLine()
      }
    }
  },
  methods: {
    drawLine() {
      this.myChart.setOption(this.setKOption(this.Kdata, this))
    },

    setKOption(Kdata, that) {
      var upColor = this.basicConfig.RiseColour
      var upBorderColor = this.basicConfig.RiseColour
      var downColor = this.basicConfig.FallColour
      var downBorderColor = this.basicConfig.FallColour
      // 数据意义：开盘(open)，收盘(close)，最低(lowest)，最高(highest)

      var data0 = splitData1()

      function splitData1() {
        var categoryData = []
        var values = []
        for (var i = 0; i < Kdata.length; i++) {
          let time = ''
          const CreateTime = getLocalTime(Kdata[i].CreateTime)
          if (that.dayMode) {
            time = CreateTime.split(' ')[0]
          } else {
            time = CreateTime.split(' ')[1]
            time = time.substr(0, 5)
          }
          categoryData.push(time)
          let arr = [Kdata[i].Open, Kdata[i].Close, Kdata[i].Low, Kdata[i].High]
          values.push(arr)
        }
        return {
          categoryData: categoryData,
          values: values
        }
      }

      return {
        grid: {
          show: false,
          left: '8%',
          right: '14%',
          bottom: '10%',
          top: '5%'
        },
        backgroundColor: '#131f30',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          }
        },
        xAxis: {
          type: 'category',
          data: data0.categoryData,
          scale: true,
          boundaryGap: false,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#4e5b85'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['#626263'],
              width: 1,
              type: 'dashed'
            }
          },
          // splitNumber: 10,
          min: 'dataMin',
          max: 'dataMax'
        },
        yAxis: {
          scale: true,
          position: 'right',
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#4e5b85'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['#4e5b85'],
              width: 1,
              type: 'dashed'
            }
          }
        },
        dataZoom: [
          {
            filterMode: 'filter',
            type: 'inside',
            start: 70,
            end: 100
          }
        ],
        markLine: {
          symbol: ['none', 'none'], //去掉箭头
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'solid',
                color: {
                  //设置渐变
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'red ' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'blue' // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              },
              label: {
                show: true,
                position: 'middle'
              }
            }
          }
        },
        series: [
          {
            // name: '日K',
            type: 'candlestick',
            data: data0.values,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: upBorderColor,
              borderColor0: downBorderColor
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.myChart = this.$echarts.init(this.$refs[this.id])
    })
  }
}
</script>

<style>
.ec-container {
  padding: 0 2px;
}
</style>
